import React from 'react';

const Footer = () => (
  <footer className="footer">
    {/* <p>
      Follow the journey: <a href="#">Facebook</a> | <a href="#">Twitter</a> | <a href="#">Instagram</a>
    </p> */}
    <p>&copy; Artchiteq New Media Entertainment. All rights reserved.</p>
  </footer>
);

export default Footer;
