import React from 'react';

const Header = () => (
  <header>
    <img src="./img/artchiteq-logo.png" alt="blade of the last gods group" height="64" width="64" />
    <h1>Blade of the Last Gods</h1>
    <p>The Animated Series Pilot Episode</p>
  </header>
);

export default Header;
