import React from 'react';

const Subscribe = () => (
  <section>
    <form
      method="post"
      action="https://sendfox.com/form/3llxen/19dedx"
      className="sendfox-form"
      id="19dedx"
      data-async="true"
      data-recaptcha="true"
    >
      <p>
        <label htmlFor="sendfox_form_email">Email: </label>
        <input type="email" id="sendfox_form_email" placeholder="Email" name="email" required />
      </p>
      <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
        <input type="text" name="a_password" tabIndex="-1" value="" autoComplete="off" />
      </div>
      <p>
        <button type="submit">Become a fan</button>
      </p>
    </form>
  </section>
);

export default Subscribe;
