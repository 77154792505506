import React from "react";

const PilotEpisode = () => {
  return (
    <div>
      <h1>Blade of the Last Gods Pilot Episode</h1>
      <div>
        {" "}
        <iframe
          width="420"
          height="345"
          src="https://www.youtube.com/embed/tgbNymZ7vqY"
          title="Blade of the Last Gods"
        ></iframe>
      </div>
    </div>
  );
};

export default PilotEpisode;
