import React from 'react';

const Journey = () => (
  <section>
    <h2>Be a Part of the Journey</h2>
    <div className="cta">
      <a href="#preorder">Pre-Order the Graphic Novel</a>
    </div>
    <div className="cta">
      <a href="#watch">Watch the Original Pilot</a>
    </div>
    <p>Stay updated on the journey—subscribe below.</p>
  </section>
);

export default Journey;
