import React from 'react';

const Goal = () => (
  <section>
    <h2>The Goal</h2>
    <p>
      To make this dream a reality, I need just 5,000 passionate fans to join me on this journey. Selling 5,000 copies of
      the book will provide the resources to revive <strong>Blade of the Last Gods</strong> and return it to its animated glory.
    </p>
    <img src="./img/animated-series.png" alt="blade of the last gods group" />
  </section>
);

export default Goal;
