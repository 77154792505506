import React from 'react';
import Header from './components/Header';
import About from './components/About';
import Goal from './components/Goal';
import Subscribe from './components/Subscribe';
import Journey from './components/Journey';
import ImageDisplay from './components/ImageDisplay';
import Footer from './components/Footer';
import './App.css';
import PilotEpisode from './components/PilotEpisode';

function App() {
  return (
    <div>
      <Header />
      <About />
      <ImageDisplay imgSrc="./img/maiLinAni.png" altText="blade of the last gods group" />
      <Goal />
      <Journey />
      <PilotEpisode />
      <Subscribe />
      <ImageDisplay imgSrc="./img/zt2.jpg" altText="blade of the last gods group" />
      <Footer />
    </div>
  );
}

export default App;
