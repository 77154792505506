import React from 'react';

const About = () => (
  <section>
    <iframe width="420" height="345" src="https://www.youtube.com/embed/tgbNymZ7vqY" title="Blade of the Last Gods"></iframe>
    <h2>About the Project</h2>
    <p>
      Back in 2010, I self-produced the pilot episode of an animated cartoon titled{' '}
      <strong>Blade of the Last Gods</strong>. Despite the talent and passion poured into the project, the high-resolution
      version of the pilot was lost. But the story doesn’t end here.
    </p>
    <p>
      Now, I’m on a mission to bring this story back to life. Starting at the very beginning, I’m creating a graphic
      novel and screenplay that tells the epic story leading up to the pilot episode.
    </p>
  </section>
);

export default About;
